import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home.vue'
import AgroalimentaireView from '../views/Agroalimentaire.vue'
import BatimentView from '../views/Batiment.vue'
import CommerceLoisirs from '../views/CommerceLoisirs.vue'
import ContactView from '../views/Contact.vue'
import DefenseSecurite from '../views/DefenseSecurite.vue'
import ElectroniqueTelecom from '../views/ElectroniqueTelecom.vue'
import EnergieNucleaire from '../views/EnergieNucleaire.vue'
import IndustrieView from '../views/Industrie.vue'
import NavalView from '../views/Naval.vue'
import PharmaceutiqueView from '../views/Pharmaceutique.vue'
import TransportsView from '../views/Transports.vue'
import QuiSommesNousView from '../views/QuiSommesNous.vue'
import EspaceClientView from '../views/EspaceClient.vue'
import NosBureaux from '../views/NosBureaux.vue'
import Recrutement from '../views/Recrutement.vue'
import MentionsLegale from '../views/MentionsLegale.vue'
// Importez les autres vues...

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/agroalimentaire',
    name: 'agroalimentaire',
    component: AgroalimentaireView
  },
  {
    path: '/batiment',
    name: 'batiment',
    component: BatimentView
  },
  {
    path: '/commerce-loisirs',
    name: 'commerce-loisirs',
    component: CommerceLoisirs
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/defense-securite',
    name: 'defense-securite',
    component: DefenseSecurite
  },
  {
    path: '/electronique-telecom',
    name: 'electronique-telecom',
    component: ElectroniqueTelecom
  },
  {
    path: '/energie-nucleaire',
    name: 'energie-nucleaire',
    component: EnergieNucleaire
  },
  {
    path: '/industrie',
    name: 'industrie',
    component: IndustrieView
  },
  {
    path: '/naval',
    name: 'naval',
    component: NavalView
  },
  {
    path: '/pharmaceutique',
    name: 'pharmaceutique',
    component: PharmaceutiqueView
  },
  {
    path: '/transports',
    name: 'transports',
    component: TransportsView
  },
  {
    path: '/qui-sommes-nous',
    name: 'qui-sommes-nous',
    component: QuiSommesNousView
  },
  {
    path: '/nos-bureaux',
    name: 'nos-bureaux',
    component: NosBureaux
  },
  {
    path: '/recrutement',
    name: 'recrutement',
    component: Recrutement
  },
  {
    path: '/mentions-legales',
    name: 'mentions-legales',
    component: MentionsLegale
  },
  {
    path: '/espace-client',
    name: 'espace-client',
    component: EspaceClientView
  }
  // Ajoutez les autres routes...
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
