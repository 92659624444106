<template>
  <div>
    <Login v-if="!authenticated" @authenticated="authenticated = true" />
    <div v-else class="container mx-auto px-4 py-8">
      <h1 class="text-3xl font-bold text-gray-800 mb-6">Espace Client</h1>
      <div class="bg-white shadow-lg rounded-lg p-6">
        <p class="text-lg text-gray-600 mb-4">
          Bienvenue dans votre espace client. Ici, vous pouvez gérer vos projets, suivre vos commandes et accéder à vos documents.
        </p>
        <!-- Ajoutez ici le contenu de l'espace client, par exemple : -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div class="bg-blue-100 p-4 rounded-lg">
            <h2 class="text-xl font-semibold mb-2">Mes Projets</h2>
            <p>Consultez et gérez vos projets en cours.</p>
          </div>
          <div class="bg-green-100 p-4 rounded-lg">
            <h2 class="text-xl font-semibold mb-2">Mes Commandes</h2>
            <p>Suivez l'état de vos commandes en temps réel.</p>
          </div>
          <div class="bg-yellow-100 p-4 rounded-lg">
            <h2 class="text-xl font-semibold mb-2">Mes Documents</h2>
            <p>Accédez à vos factures et autres documents importants.</p>
          </div>
          <div class="bg-purple-100 p-4 rounded-lg">
            <h2 class="text-xl font-semibold mb-2">Support</h2>
            <p>Contactez notre équipe pour toute assistance.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Login from '@/components/Login.vue'

export default {
  name: 'EspaceClientView',
  components: {
    Login
  },
  data() {
    return {
      authenticated: false
    }
  }
}
</script>
