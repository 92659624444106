<template>
  <div class="max-w-sm mx-auto mt-10 p-6 bg-white rounded-lg shadow-xl">
    <h1 class="text-2xl font-bold mb-6 text-gray-800">Connexion</h1>
    <form @submit.prevent="checkPassword">
      <div class="mb-4">
        <label for="password" class="block text-gray-700 text-sm font-bold mb-2">Mot de passe :</label>
        <input type="password" id="password" v-model="password" required
               class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500">
      </div>
      <button type="submit"
              class="w-full bg-blue-500 text-white font-bold py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
        Se connecter
      </button>
      <p v-if="error" class="text-red-500 mt-4">{{ error }}</p>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: '',
      error: ''
    }
  },
  methods: {
    checkPassword() {
      if (this.password === 'admin2024') {
        this.$emit('authenticated')
      } else {
        this.error = 'Mot de passe incorrect'
      }
    }
  }
}
</script>

<style scoped>
/* Ajoutez des styles spécifiques ici si nécessaire */
</style>
