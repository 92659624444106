<template>
  <div class="container mx-auto px-4 py-8">
    <h1 class="text-3xl font-bold text-gray-800 mb-4">Chaudronnerie industrielle pour le secteur des transports</h1>
    
    <div class="grid md:grid-cols-2 gap-8 mb-12">
      <div>
        <p class="text-lg text-gray-600 mb-6">
          Dans le secteur des transports, qu'il s'agisse de l'automobile, de l'aéronautique ou du ferroviaire, la durabilité et la légèreté des structures sont essentielles. Notre expertise en chaudronnerie nous permet de réaliser des pièces de précision répondant aux exigences strictes de ces industries.
        </p>
        <p class="text-lg text-gray-600 mb-6">
          Nos solutions comprennent :
        </p>
        <ul class="list-disc list-inside text-lg text-gray-600 mb-6 space-y-2">
          <li>Structures allégées en aluminium</li>
          <li>Composants de carrosserie</li>
          <li>Pièces techniques sur mesure</li>
          <li>Systèmes d'échappement</li>
          <li>Équipements de maintenance</li>
        </ul>
      </div>
      <div class="h-[400px]">
        <img src="@/assets/images/image_cibao.jpg" alt="Transport" class="rounded-lg shadow-lg w-full h-full object-cover">
      </div>
    </div>

    <div class="bg-gray-50 rounded-lg p-8 mb-12">
      <h2 class="text-2xl font-bold text-gray-800 mb-6">Notre expertise transport</h2>
      <div class="grid md:grid-cols-3 gap-6">
        <div class="bg-white p-6 rounded-lg shadow">
          <h3 class="text-xl font-semibold mb-4">Certifications</h3>
          <p class="text-gray-600">Conformité aux normes de l'industrie automobile et aéronautique (ISO/TS 16949, EN 9100).</p>
        </div>
        <div class="bg-white p-6 rounded-lg shadow">
          <h3 class="text-xl font-semibold mb-4">Innovation</h3>
          <p class="text-gray-600">Utilisation de matériaux innovants et de techniques d'allègement structural.</p>
        </div>
        <div class="bg-white p-6 rounded-lg shadow">
          <h3 class="text-xl font-semibold mb-4">Précision</h3>
          <p class="text-gray-600">Fabrication de haute précision pour répondre aux tolérances strictes du secteur.</p>
        </div>
      </div>
    </div>

    <div class="grid md:grid-cols-2 gap-8 mb-12">
      <div class="h-[400px]">
        <img src="@/assets/images/image_cibao.jpg" alt="Industrie Transport" class="rounded-lg shadow-lg w-full h-full object-cover">
      </div>
      <div>
        <h2 class="text-2xl font-bold text-gray-800 mb-6">Secteurs d'intervention</h2>
        <p class="text-lg text-gray-600 mb-6">
          Notre expertise s'étend à tous les domaines du transport :
        </p>
        <ul class="list-disc list-inside text-lg text-gray-600 space-y-2">
          <li>Industrie automobile</li>
          <li>Secteur aéronautique</li>
          <li>Transport ferroviaire</li>
          <li>Transport maritime</li>
          <li>Véhicules spéciaux</li>
        </ul>
      </div>
    </div>

    <div class="bg-blue-50 rounded-lg p-8">
      <h2 class="text-2xl font-bold text-gray-800 mb-6">Services spécialisés</h2>
      <div class="grid md:grid-cols-2 gap-8">
        <div>
          <h3 class="text-xl font-semibold mb-4">Conception et prototypage</h3>
          <p class="text-gray-600 mb-4">
            De l'idée à la réalisation, nous vous accompagnons dans le développement de vos projets.
          </p>
          <ul class="list-disc list-inside text-gray-600">
            <li>Modélisation 3D</li>
            <li>Prototypes fonctionnels</li>
            <li>Tests et validations</li>
          </ul>
        </div>
        <div>
          <h3 class="text-xl font-semibold mb-4">Production série</h3>
          <p class="text-gray-600 mb-4">
            Capacité de production en série avec contrôle qualité rigoureux.
          </p>
          <ul class="list-disc list-inside text-gray-600">
            <li>Gestion de production</li>
            <li>Contrôle qualité</li>
            <li>Traçabilité complète</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head'

export default {
  name: 'TransportsView',
  setup() {
    useHead({
      title: 'Transports - CIBAO Solutions Métalliques',
      meta: [
        { name: 'description', content: 'Solutions de chaudronnerie pour le secteur des transports. Structures légères et durables pour automobile, aéronautique et ferroviaire. Expert à Saint-Nazaire.' },
        { name: 'keywords', content: 'chaudronnerie transport, structures métalliques, automobile, aéronautique, ferroviaire, Saint-Nazaire' },
        { property: 'og:title', content: 'Solutions Transport - CIBAO' },
        { property: 'og:description', content: 'Expert en solutions de chaudronnerie pour le secteur des transports' },
        { property: 'og:url', content: 'https://cibao.fr/transports' }
      ]
    })
  }
}
</script>
