<template>
  <div id="app" class="min-h-screen bg-pattern">
    <div class="bg-overlay">
      <HeaderComponent />
      <router-view></router-view>
      <FooterComponent />
    </div>
  </div>
</template>

<script>
import HeaderComponent from '@/components/common/Header.vue'
import FooterComponent from '@/components/common/Footer.vue'

export default {
  name: 'App',
  components: {
    HeaderComponent,
    FooterComponent
  }
}
</script>

<style>
.bg-pattern {
  background-image: url('@/assets/images/fond.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.bg-overlay {
  background-color: rgba(219, 234, 254, 0.95); /* bg-blue-100 avec 95% d'opacité */
  min-height: 100vh;
}
</style>
