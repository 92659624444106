<template>
    <div>
      <div class="container mx-auto px-4 py-12">
        <h1 class="text-4xl font-bold text-blue-900 mb-8 text-center">Nos bureaux</h1>
        
        <div class="bg-white shadow-lg rounded-lg overflow-hidden mb-12">
          <div class="md:flex">
            <div class="md:w-1/2 p-6">
              <h2 class="text-3xl font-bold text-blue-800 mb-4">CIBAO</h2>
              <p class="text-lg text-gray-600 mb-4">
                Avec plus de 20 ans d'expérience, CIBAO s'est imposé comme un leader dans le domaine de la tuyauterie industrielle. Notre expertise et notre engagement envers l'excellence nous ont permis de devenir un partenaire de confiance pour de nombreux secteurs industriels.
              </p>
              <p class="text-lg text-gray-600">
                Chez CIBAO, nous croyons en l'importance de l'écoute client, de la qualité du travail et du respect des délais. Notre mission est de contribuer au succès de nos clients en leur fournissant des équipements fiables et performants.
              </p>
            </div>
            <div class="md:w-1/2 bg-blue-900 flex items-center justify-center">
              <img src="@/assets/images/image_cibao.jpg" alt="CIBAO Chaudronnerie" class="object-cover h-full w-full">
            </div>
          </div>
        </div>

        <div class="bg-white shadow-lg rounded-lg overflow-hidden mb-12">
          <div class="p-6">
            <h2 class="text-2xl font-bold text-blue-800 mb-4">Nous contacter</h2>
            <div class="flex items-center mb-4">
              <i class="fas fa-map-marker-alt text-blue-600 text-xl mr-4"></i>
              <p class="text-lg">5 rue de Trignac, 44600 SAINT-NAZAIRE, France</p>
            </div>
            <div class="flex items-center mb-4">
              <i class="fas fa-phone text-blue-600 text-xl mr-4"></i>
              <a href="tel:0228546027" class="text-lg hover:text-blue-600">02 28 54 60 27</a>
            </div>
          </div>
          <div class="h-[400px]">
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2709.0436743850385!2d-2.2234844842089843!3d47.28674977916514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4805798c7d0b0001%3A0x8b0f0b0b0b0b0b0b!2s5%20Rue%20de%20Trignac%2C%2044600%20Saint-Nazaire!5e0!3m2!1sfr!2sfr!4v1635789123456!5m2!1sfr!2sfr"
              width="100%"
              height="100%"
              style="border:0;"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade">
            </iframe>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { useHead } from '@vueuse/head'

  export default {
    name: 'NosBureauxView',
    setup() {
      useHead({
        title: 'Nos Bureaux - CIBAO Saint-Nazaire',
        meta: [
          { name: 'description', content: 'Découvrez nos bureaux et ateliers à Saint-Nazaire. CIBAO, expert en chaudronnerie et tuyauterie industrielle avec plus de 1000m² d\'espace de production.' },
          { name: 'keywords', content: 'CIBAO Saint-Nazaire, atelier chaudronnerie, bureaux CIBAO, localisation' },
          { property: 'og:title', content: 'Nos Bureaux - CIBAO Saint-Nazaire' },
          { property: 'og:description', content: 'Bureaux et ateliers CIBAO à Saint-Nazaire' },
          { property: 'og:url', content: 'https://cibao.fr/nos-bureaux' }
        ]
      })
    }
  }
  </script>
  