<template>
  <div class="container mx-auto px-4 py-8">
    <h1 class="text-3xl font-bold text-gray-800 mb-4">Chaudronnerie pour l'industrie pharmaceutique</h1>
    
    <div class="grid md:grid-cols-2 gap-8 mb-12">
      <div>
        <p class="text-lg text-gray-600 mb-6">
          Dans l'industrie pharmaceutique, la précision et la conformité sont essentielles. Nous proposons des solutions de chaudronnerie adaptées aux environnements stériles, où l'hygiène et la sécurité sont primordiales.
        </p>
        <p class="text-lg text-gray-600 mb-6">
          Nos équipements répondent aux normes GMP (Good Manufacturing Practice) et aux exigences strictes de l'industrie pharmaceutique :
        </p>
        <ul class="list-disc list-inside text-lg text-gray-600 mb-6 space-y-2">
          <li>Cuves de production stériles</li>
          <li>Systèmes de filtration</li>
          <li>Réacteurs pharmaceutiques</li>
          <li>Tuyauteries aseptiques</li>
          <li>Équipements de laboratoire</li>
        </ul>
      </div>
      <div class="h-[400px]">
        <img src="@/assets/images/image_cibao.jpg" alt="Laboratoire Pharmaceutique" class="rounded-lg shadow-lg w-full h-full object-cover">
      </div>
    </div>

    <div class="bg-gray-50 rounded-lg p-8 mb-12">
      <h2 class="text-2xl font-bold text-gray-800 mb-6">Standards de qualité</h2>
      <div class="grid md:grid-cols-3 gap-6">
        <div class="bg-white p-6 rounded-lg shadow">
          <h3 class="text-xl font-semibold mb-4">Normes GMP</h3>
          <p class="text-gray-600">Conformité aux bonnes pratiques de fabrication et aux standards internationaux.</p>
        </div>
        <div class="bg-white p-6 rounded-lg shadow">
          <h3 class="text-xl font-semibold mb-4">Matériaux validés</h3>
          <p class="text-gray-600">Utilisation exclusive de matériaux approuvés pour l'industrie pharmaceutique.</p>
        </div>
        <div class="bg-white p-6 rounded-lg shadow">
          <h3 class="text-xl font-semibold mb-4">Documentation</h3>
          <p class="text-gray-600">Traçabilité complète et documentation détaillée pour chaque équipement.</p>
        </div>
      </div>
    </div>

    <div class="grid md:grid-cols-2 gap-8 mb-12">
      <div class="h-[400px]">
        <img src="@/assets/images/image_cibao.jpg" alt="Équipement Pharmaceutique" class="rounded-lg shadow-lg w-full h-full object-cover">
      </div>
      <div>
        <h2 class="text-2xl font-bold text-gray-800 mb-6">Domaines d'application</h2>
        <p class="text-lg text-gray-600 mb-6">
          Nos solutions couvrent l'ensemble de la chaîne de production pharmaceutique :
        </p>
        <ul class="list-disc list-inside text-lg text-gray-600 space-y-2">
          <li>Production de médicaments</li>
          <li>Biotechnologie</li>
          <li>Recherche et développement</li>
          <li>Cosmétique</li>
          <li>Dispositifs médicaux</li>
        </ul>
      </div>
    </div>

    <div class="bg-blue-50 rounded-lg p-8">
      <h2 class="text-2xl font-bold text-gray-800 mb-6">Services spécialisés</h2>
      <div class="grid md:grid-cols-2 gap-8">
        <div>
          <h3 class="text-xl font-semibold mb-4">Validation et qualification</h3>
          <p class="text-gray-600 mb-4">
            Accompagnement complet dans les processus de validation des équipements.
          </p>
          <ul class="list-disc list-inside text-gray-600">
            <li>Qualification de conception (DQ)</li>
            <li>Qualification d'installation (IQ)</li>
            <li>Qualification opérationnelle (OQ)</li>
          </ul>
        </div>
        <div>
          <h3 class="text-xl font-semibold mb-4">Maintenance spécialisée</h3>
          <p class="text-gray-600 mb-4">
            Services de maintenance adaptés aux environnements pharmaceutiques.
          </p>
          <ul class="list-disc list-inside text-gray-600">
            <li>Maintenance préventive</li>
            <li>Interventions en zone stérile</li>
            <li>Calibration et contrôles</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head'

export default {
  name: 'PharmaceutiqueView',
  setup() {
    useHead({
      title: 'Pharmaceutique - CIBAO Solutions Stériles',
      meta: [
        { name: 'description', content: 'Solutions de chaudronnerie pour l\'industrie pharmaceutique. Équipements adaptés aux environnements stériles. Expert à Saint-Nazaire.' },
        { name: 'keywords', content: 'chaudronnerie pharmaceutique, équipements stériles, tuyauterie pharmaceutique, Saint-Nazaire' },
        { property: 'og:title', content: 'Solutions Pharmaceutiques - CIBAO' },
        { property: 'og:description', content: 'Expert en solutions de chaudronnerie pour l\'industrie pharmaceutique' },
        { property: 'og:url', content: 'https://cibao.fr/pharmaceutique' }
      ]
    })
  }
}
</script>
