import { createApp } from 'vue'
import { createHead } from '@vueuse/head'
import { Quasar } from 'quasar'
import quasarUserOptions from './quasar-user-options'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'

// Import Font Awesome
import '@fortawesome/fontawesome-free/css/all.css'

// Import icon libraries
import '@quasar/extras/material-icons/material-icons.css'

// Import Quasar css
import 'quasar/src/css/index.sass'

const app = createApp(App)
const head = createHead()

app.use(head)
app.use(Quasar, quasarUserOptions)
app.use(router)

app.mount('#app')
