<template>
  <div class="container mx-auto px-4 py-8">
    <h1 class="text-3xl font-bold text-gray-800 mb-4">Tuyauterie pour le secteur du bâtiment</h1>
    
    <div class="grid md:grid-cols-2 gap-8 mb-12">
      <div>
        <p class="text-lg text-gray-600 mb-6">
          Dans le bâtiment, la construction métallique joue un rôle clé dans la création de structures durables et modernes. Notre expertise en chaudronnerie nous permet de réaliser des ouvrages métalliques complexes et sur mesure.
        </p>
        <p class="text-lg text-gray-600 mb-6">
          Nos réalisations comprennent :
        </p>
        <ul class="list-disc list-inside text-lg text-gray-600 mb-6 space-y-2">
          <li>Structures métalliques architecturales</li>
          <li>Escaliers et garde-corps</li>
          <li>Charpentes métalliques</li>
          <li>Passerelles et plateformes</li>
          <li>Habillages et bardages</li>
        </ul>
      </div>
      <div class="h-[400px]">
        <img src="@/assets/images/image_cibao.jpg" alt="Construction Métallique" class="rounded-lg shadow-lg w-full h-full object-cover">
      </div>
    </div>

    <div class="bg-gray-50 rounded-lg p-8 mb-12">
      <h2 class="text-2xl font-bold text-gray-800 mb-6">Notre expertise construction</h2>
      <div class="grid md:grid-cols-3 gap-6">
        <div class="bg-white p-6 rounded-lg shadow">
          <h3 class="text-xl font-semibold mb-4">Conformité</h3>
          <p class="text-gray-600">Respect des normes de construction DTU et Eurocodes. Certification EN 1090 pour les structures métalliques.</p>
        </div>
        <div class="bg-white p-6 rounded-lg shadow">
          <h3 class="text-xl font-semibold mb-4">Conception</h3>
          <p class="text-gray-600">Bureau d'études intégré pour la conception et le dimensionnement des ouvrages.</p>
        </div>
        <div class="bg-white p-6 rounded-lg shadow">
          <h3 class="text-xl font-semibold mb-4">Installation</h3>
          <p class="text-gray-600">Équipes qualifiées pour le montage et l'installation sur site.</p>
        </div>
      </div>
    </div>

    <div class="grid md:grid-cols-2 gap-8 mb-12">
      <div class="h-[400px]">
        <img src="@/assets/images/image_cibao.jpg" alt="Structures Métalliques" class="rounded-lg shadow-lg w-full h-full object-cover">
      </div>
      <div>
        <h2 class="text-2xl font-bold text-gray-800 mb-6">Applications spécifiques</h2>
        <p class="text-lg text-gray-600 mb-6">
          Nos solutions s'adaptent à tous types de bâtiments :
        </p>
        <ul class="list-disc list-inside text-lg text-gray-600 space-y-2">
          <li>Bâtiments industriels</li>
          <li>Immeubles de bureaux</li>
          <li>Centres commerciaux</li>
          <li>Équipements publics</li>
          <li>Bâtiments résidentiels</li>
        </ul>
      </div>
    </div>

    <div class="bg-blue-50 rounded-lg p-8">
      <h2 class="text-2xl font-bold text-gray-800 mb-6">Services complémentaires</h2>
      <div class="grid md:grid-cols-2 gap-8">
        <div>
          <h3 class="text-xl font-semibold mb-4">Études et conception</h3>
          <p class="text-gray-600 mb-4">
            Notre bureau d'études assure la conception et le dimensionnement de vos projets.
          </p>
          <ul class="list-disc list-inside text-gray-600">
            <li>Modélisation 3D</li>
            <li>Calculs de structure</li>
            <li>Plans d'exécution</li>
          </ul>
        </div>
        <div>
          <h3 class="text-xl font-semibold mb-4">Maintenance et rénovation</h3>
          <p class="text-gray-600 mb-4">
            Services de maintenance et rénovation pour vos structures existantes.
          </p>
          <ul class="list-disc list-inside text-gray-600">
            <li>Diagnostic structure</li>
            <li>Renforcement</li>
            <li>Mise aux normes</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head'

export default {
  name: 'BatimentView',
  setup() {
    useHead({
      title: 'Bâtiment - CIBAO Construction Métallique',
      meta: [
        { name: 'description', content: 'Solutions de construction métallique et chaudronnerie pour le secteur du bâtiment. Structures durables et modernes. Expert à Saint-Nazaire.' },
        { name: 'keywords', content: 'construction métallique, chaudronnerie bâtiment, structures métalliques, Saint-Nazaire' },
        { property: 'og:title', content: 'Construction Métallique - CIBAO' },
        { property: 'og:description', content: 'Expert en construction métallique et chaudronnerie pour le bâtiment' },
        { property: 'og:url', content: 'https://cibao.fr/batiment' }
      ]
    })
  }
}
</script>
