<template>
  <footer class="bg-gray-800 text-white py-8">
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap justify-between">
        <div class="w-full md:w-1/3 mb-6 md:mb-0">
          <h3 class="text-xl font-bold mb-2">CIBAO</h3>
          <p>Votre expert en tuyauterie industrielle</p>
        </div>
        <div class="w-full md:w-1/3 mb-6 md:mb-0">
          <h3 class="text-xl font-bold mb-2">Contact</h3>
          <p><i class="fas fa-map-marker-alt mr-2"></i>5 rue de Trignac, 44600 SAINT-NAZAIRE, France</p>
          <p><i class="fas fa-phone mr-2"></i>Tél : 02 28 54 60 27</p>
          <p><i class="fas fa-globe mr-2"></i>Intl : +33 228 546 027</p>
          <p><i class="fas fa-envelope mr-2"></i>Email : info@cibao.fr</p>
        </div>
        <div class="w-full md:w-1/3">
          <h3 class="text-xl font-bold mb-2">Liens rapides</h3>
          <ul>
            <li><router-link to="/" class="hover:text-blue-300">Accueil</router-link></li>
            <li><router-link to="/" class="hover:text-blue-300">Nous trouver</router-link></li>
            <li><router-link to="/" class="hover:text-blue-300">Recrutement</router-link></li>
            <li><router-link to="/contact" class="hover:text-blue-300">Contact</router-link></li>
          </ul>
        </div>
      </div>
      <div class="mt-8 text-center">
        <p>&copy; 2022-2025 CIBAO <i class="fas fa-registered"></i> Tous droits réservés. | <router-link to="/mentions-legales" class="hover:text-blue-300">Mentions Légales</router-link></p>
      </div>
    </div>
    <div class="mt-4 text-center text-xs text-gray-500">
      <a href="https://mywebessential.com" target="_blank" rel="noopener noreferrer">
        <img src="@/assets/images/essential.png" alt="Essential" class="mx-auto h-6 opacity-50 hover:opacity-100 transition-opacity" />
      </a>
    </div>
  </footer>
</template>

<script>
import { useHead } from '@vueuse/head'

export default {
  name: 'FooterComponent',
  setup() {
    useHead({
      meta: [
        { name: 'author', content: 'CIBAO' },
        { name: 'copyright', content: '© 2022-2025 CIBAO' }
      ]
    })
  }
}
</script>

<style scoped>
/* Styles du footer */
</style>
