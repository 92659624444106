<template>
  <div class="container mx-auto px-4 py-8">
    <h1 class="text-3xl font-bold text-gray-800 mb-4">Tuyauterie industrielle pour les applications industrielles</h1>
    
    <div class="grid md:grid-cols-2 gap-8 mb-12">
      <div>
        <p class="text-lg text-gray-600 mb-6">
          La chaudronnerie industrielle est au cœur des processus industriels, fournissant des solutions sur mesure pour les équipements de production. Notre expertise nous permet de concevoir et fabriquer des structures métalliques complexes répondant aux exigences les plus strictes.
        </p>
        <p class="text-lg text-gray-600 mb-6">
          Nous intervenons dans la conception, la fabrication et l'installation de :
        </p>
        <ul class="list-disc list-inside text-lg text-gray-600 mb-6 space-y-2">
          <li>Cuves et réservoirs industriels</li>
          <li>Tuyauteries et réseaux de process</li>
          <li>Structures métalliques sur mesure</li>
          <li>Équipements de production spécialisés</li>
          <li>Solutions de stockage industriel</li>
        </ul>
      </div>
      <div>
        <img src="@/assets/images/image_cibao.jpg" alt="Industrie" class="rounded-lg shadow-lg w-full h-full object-cover">
      </div>
    </div>

    <div class="bg-gray-50 rounded-lg p-8 mb-12">
      <h2 class="text-2xl font-bold text-gray-800 mb-6">Notre expertise en chaudronnerie industrielle</h2>
      <div class="grid md:grid-cols-3 gap-6">
        <div class="bg-white p-6 rounded-lg shadow">
          <h3 class="text-xl font-semibold mb-4">Qualité certifiée</h3>
          <p class="text-gray-600">Nos processus de fabrication respectent les normes ISO 9001 et EN 1090, garantissant une qualité optimale.</p>
        </div>
        <div class="bg-white p-6 rounded-lg shadow">
          <h3 class="text-xl font-semibold mb-4">Sur mesure</h3>
          <p class="text-gray-600">Chaque projet est unique et bénéficie d'une approche personnalisée pour répondre à vos besoins spécifiques.</p>
        </div>
        <div class="bg-white p-6 rounded-lg shadow">
          <h3 class="text-xl font-semibold mb-4">Service complet</h3>
          <p class="text-gray-600">De la conception à l'installation, nous vous accompagnons à chaque étape de votre projet.</p>
        </div>
      </div>
    </div>

    <div class="grid md:grid-cols-2 gap-8 mb-12">
      <div>
        <img src="@/assets/images/image_cibao.jpg" alt="Process industriel" class="rounded-lg shadow-lg w-full h-full object-cover">
      </div>
      <div>
        <h2 class="text-2xl font-bold text-gray-800 mb-6">Solutions innovantes</h2>
        <p class="text-lg text-gray-600 mb-6">
          Notre équipe d'experts utilise les dernières technologies pour concevoir des solutions innovantes répondant aux défis de l'industrie moderne. Nous nous engageons à fournir des équipements durables et performants.
        </p>
        <p class="text-lg text-gray-600">
          Nos solutions sont adaptées à différents secteurs industriels :
        </p>
        <ul class="list-disc list-inside text-lg text-gray-600 space-y-2">
          <li>Industrie manufacturière</li>
          <li>Industrie chimique</li>
          <li>Industrie métallurgique</li>
          <li>Industrie énergétique</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head'

export default {
  name: 'IndustrieView',
  setup() {
    useHead({
      title: 'Industrie - CIBAO Tuyauterie et Chaudronnerie',
      meta: [
        { name: 'description', content: 'Solutions de chaudronnerie industrielle et tuyauterie sur mesure pour les applications industrielles. Expert basé à Saint-Nazaire.' },
        { name: 'keywords', content: 'chaudronnerie industrielle, tuyauterie industrielle, équipements industriels, Saint-Nazaire' },
        { property: 'og:title', content: 'Solutions de Chaudronnerie Industrielle - CIBAO' },
        { property: 'og:description', content: 'Expert en solutions de chaudronnerie et tuyauterie pour l\'industrie' },
        { property: 'og:url', content: 'https://cibao.fr/industrie' }
      ]
    })
  }
}
</script>
