<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable comma-dangle -->
<template>
  <div class="home">
    <div class="hero relative h-screen flex items-center justify-center overflow-hidden">
      <video autoplay loop muted playsinline class="absolute z-10 w-auto min-w-full min-h-full max-w-none">
        <source src="@/assets/video/soudure.mp4" type="video/mp4" />
        <source src="@/assets/video/soudure.webm" type="video/webm" />
        Votre navigateur ne supporte pas la balise vidéo.
        Your browser does not support the video tag.
      </video>
      <div class="z-20 text-white text-center relative">
        <h1 class="text-5xl font-bold mb-4">CIBAO</h1>
        <p class="text-xl mb-8">Votre expert en tuyauterie industrielle</p>
        <a href="#services" class="bg-white text-blue-900 py-2 px-6 rounded-full font-bold hover:bg-blue-100 transition duration-300">Découvrir nos services</a>
      </div>
    </div>

    <section id="services" class="py-16">
      <div class="container mx-auto px-4">
        <h2 class="text-3xl font-bold text-center mb-12">Nos domaines d'expertise</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div v-for="service in services" :key="service.name" class="bg-white shadow-lg rounded-lg overflow-hidden transition-all duration-300 hover:shadow-xl hover:-translate-y-2">
            <div class="p-6">
              <div class="text-4xl text-blue-600 mb-4 flex justify-center">
                <i :class="service.icon"></i>
              </div>
              <h3 class="text-xl font-semibold mb-2 text-center">{{ service.name }}</h3>
              <p class="text-gray-600 text-center">{{ service.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="py-16 bg-gray-100">
      <div class="container mx-auto px-4">
        <h2 class="text-3xl font-bold text-center mb-12">Votre projet en 4 étapes</h2>
        <div class="relative">
          <div class="absolute left-1/2 top-0 bottom-0 w-1 bg-blue-600 transform -translate-x-1/2"></div>
          <div class="space-y-12">
            <div v-for="(step, index) in projectSteps" :key="index" class="relative">
              <div class="flex items-center justify-between">
                <div class="w-5/12" :class="index % 2 === 0 ? 'order-1' : 'order-3'">
                  <img :src="stepImages[index]" :alt="step.title" class="w-full h-48 object-cover rounded-lg shadow-md">
                </div>
                <div class="absolute left-1/2 transform -translate-x-1/2 w-12 h-12 bg-blue-600 rounded-full flex items-center justify-center text-white font-bold text-xl z-10 order-2">
                  {{ index + 1 }}
                </div>
                <div class="w-5/12 bg-white p-6 rounded-lg shadow-md transition-all duration-300 hover:shadow-xl" :class="index % 2 === 0 ? 'order-3 text-left' : 'order-1 text-right'">
                  <div class="text-4xl text-blue-600 mb-4" :class="index % 2 === 0 ? 'text-left' : 'text-right'">
                    <i :class="step.icon"></i>
                  </div>
                  <h3 class="text-xl font-semibold mb-2">{{ step.title }}</h3>
                  <p class="text-gray-600">{{ step.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-gray-100 py-16">
      <div class="container mx-auto px-4 text-center">
        <h2 class="text-3xl font-bold mb-8">Pourquoi choisir CIBAO ?</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <i class="fas fa-cogs text-4xl text-blue-900 mb-4"></i>
            <h3 class="text-xl font-semibold mb-2">Expertise technique</h3>
            <p>Plus de 20 ans d'expérience dans l'industrie</p>
          </div>
          <div>
            <i class="fas fa-certificate text-4xl text-blue-900 mb-4"></i>
            <h3 class="text-xl font-semibold mb-2">Qualité certifiée</h3>
            <p>Certifications ISO 9001 et EN 1090</p>
          </div>
          <div>
            <i class="fas fa-users text-4xl text-blue-900 mb-4"></i>
            <h3 class="text-xl font-semibold mb-2">Service client</h3>
            <p>Une équipe dédiée à votre satisfaction</p>
          </div>
        </div>
      </div>
    </section>

    <!-- Nouveau bandeau CTA -->
    <section class="bg-blue-600 py-12 text-white">
      <div class="container mx-auto px-4 text-center">
        <h2 class="text-3xl font-bold mb-4">Discutons de vos projets avec nos experts</h2>
        <p class="text-xl mb-8">Contactez-nous dès aujourd'hui pour votre projet</p>
        <div class="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
          <a href="tel:+33228546027" class="bg-white text-blue-600 hover:bg-blue-100 transition duration-300 py-2 px-6 rounded-full font-bold text-lg flex items-center">
            <i class="fas fa-phone-alt mr-2"></i>
            +33 228 546 027 
          </a>
          <router-link to="/contact" class="bg-blue-800 hover:bg-blue-700 transition duration-300 py-2 px-6 rounded-full font-bold text-lg">
            Nous contacter
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head'
import step1 from '@/assets/images/step/step1.jpg'
import step2 from '@/assets/images/step/step2.jpg'
import step3 from '@/assets/images/step/step3.jpg'
import step4 from '@/assets/images/step/step4.jpg'

export default {
  name: 'HomeView',
  setup() {
    useHead({
      title: 'CIBAO - Expert en tuyauterie industrielle et chaudronnerie',
      meta: [
        { name: 'description', content: 'CIBAO, votre expert en tuyauterie industrielle et chaudronnerie à Saint-Nazaire. Solutions sur mesure pour l\'industrie, l\'agroalimentaire et l\'énergie.' },
        { property: 'og:title', content: 'CIBAO - Expert en tuyauterie industrielle' },
        { property: 'og:description', content: 'Expert en tuyauterie industrielle et chaudronnerie à Saint-Nazaire' },
        { property: 'og:image', content: step1 },
        { property: 'og:url', content: 'https://cibao.fr' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'keywords', content: 'tuyauterie industrielle, chaudronnerie, Saint-Nazaire, industrie, agroalimentaire, énergie' }
      ]
    })
  },
  data() {
    return {
      projectSteps: [
        {
          icon: 'fas fa-search',
          title: "Étude",
          description: "Audit, mesures, modélisation et plans",
        },
        {
          icon: 'fas fa-clipboard-list',
          title: "Fournitures",
          description: "Commandes et contrôle qualité",
        },
        {
          icon: 'fas fa-tools',
          title: "Préparation",
          description: "Installation, préfabrication et traitements",
        },
        {
          icon: 'fas fa-truck',
          title: "Installation",
          description: "Montage, assemblage et tests",
        }
      ],
      services: [
        { name: 'Industrie', description: 'Solutions sur mesure pour l\'industrie', icon: 'fas fa-industry' },
        { name: 'Agroalimentaire', description: 'Équipements conformes aux normes sanitaires', icon: 'fas fa-utensils' },
        { name: 'Énergie', description: 'Structures fiables pour le secteur énergétique', icon: 'fas fa-bolt' },
        { name: 'Bâtiment', description: 'Construction métallique pour structures durables', icon: 'fas fa-building' },
        { name: 'Naval', description: 'Équipements résistants aux conditions marines', icon: 'fas fa-ship' },
        { name: 'Pharmaceutique', description: 'Solutions adaptées aux environnements stériles', icon: 'fas fa-pills' },
        { name: 'Transports', description: 'Structures légères et durables pour tous types de transports', icon: 'fas fa-bus' },
        { name: 'Défense et Sécurité', description: 'Équipements robustes et fiables', icon: 'fas fa-shield-alt' },
        { name: 'Électronique et Télécom', description: 'Structures précises et résistantes aux interférences', icon: 'fas fa-microchip' }
      ],
      stepImages: [step1, step2, step3, step4]
    }
  }
}
</script>

<style>
/* Vous pouvez ajouter des styles spécifiques ici si nécessaire */
</style>

<style scoped>
.space-y-12 > div:not(:last-child) {
  padding-bottom: 3rem;
}
</style>
