<template>
  <div class="container mx-auto px-4 py-8">
    <h1 class="text-3xl font-bold text-gray-800 mb-4">Tuyauterie pour le commerce et les loisirs</h1>
    
    <div class="grid md:grid-cols-2 gap-8 mb-12">
      <div>
        <p class="text-lg text-gray-600 mb-6">
          Dans les secteurs du commerce, des loisirs et du luxe, la qualité de présentation et la résistance des structures sont primordiales. Nous concevons des solutions alliant esthétique et durabilité pour créer des espaces attractifs et fonctionnels.
        </p>
        <p class="text-lg text-gray-600 mb-6">
          Nos réalisations comprennent :
        </p>
        <ul class="list-disc list-inside text-lg text-gray-600 mb-6 space-y-2">
          <li>Agencements commerciaux</li>
          <li>Mobilier urbain design</li>
          <li>Structures pour parcs de loisirs</li>
          <li>Équipements sportifs</li>
          <li>Aménagements de luxe</li>
        </ul>
      </div>
      <div class="h-[400px]">
        <img src="@/assets/images/image_cibao.jpg" alt="Commerce" class="rounded-lg shadow-lg w-full h-full object-cover">
      </div>
    </div>

    <div class="bg-gray-50 rounded-lg p-8 mb-12">
      <h2 class="text-2xl font-bold text-gray-800 mb-6">Notre expertise design</h2>
      <div class="grid md:grid-cols-3 gap-6">
        <div class="bg-white p-6 rounded-lg shadow">
          <h3 class="text-xl font-semibold mb-4">Esthétique</h3>
          <p class="text-gray-600">Finitions soignées et design contemporain pour des réalisations élégantes.</p>
        </div>
        <div class="bg-white p-6 rounded-lg shadow">
          <h3 class="text-xl font-semibold mb-4">Durabilité</h3>
          <p class="text-gray-600">Matériaux de qualité supérieure pour une résistance optimale dans le temps.</p>
        </div>
        <div class="bg-white p-6 rounded-lg shadow">
          <h3 class="text-xl font-semibold mb-4">Sur-mesure</h3>
          <p class="text-gray-600">Création personnalisée adaptée à votre image de marque et vos besoins.</p>
        </div>
      </div>
    </div>

    <div class="grid md:grid-cols-2 gap-8 mb-12">
      <div class="h-[400px]">
        <img src="@/assets/images/image_cibao.jpg" alt="Design" class="rounded-lg shadow-lg w-full h-full object-cover">
      </div>
      <div>
        <h2 class="text-2xl font-bold text-gray-800 mb-6">Domaines d'application</h2>
        <p class="text-lg text-gray-600 mb-6">
          Nos solutions s'adaptent à différents secteurs :
        </p>
        <ul class="list-disc list-inside text-lg text-gray-600 space-y-2">
          <li>Centres commerciaux</li>
          <li>Boutiques de luxe</li>
          <li>Espaces de loisirs</li>
          <li>Installations sportives</li>
          <li>Hôtellerie haut de gamme</li>
        </ul>
      </div>
    </div>

    <div class="bg-blue-50 rounded-lg p-8">
      <h2 class="text-2xl font-bold text-gray-800 mb-6">Services spécialisés</h2>
      <div class="grid md:grid-cols-2 gap-8">
        <div>
          <h3 class="text-xl font-semibold mb-4">Conception créative</h3>
          <p class="text-gray-600 mb-4">
            De l'idée à la réalisation, nous vous accompagnons dans vos projets innovants.
          </p>
          <ul class="list-disc list-inside text-gray-600">
            <li>Design personnalisé</li>
            <li>Modélisation 3D</li>
            <li>Prototypage</li>
          </ul>
        </div>
        <div>
          <h3 class="text-xl font-semibold mb-4">Installation et finition</h3>
          <p class="text-gray-600 mb-4">
            Une mise en œuvre soignée pour un résultat impeccable.
          </p>
          <ul class="list-disc list-inside text-gray-600">
            <li>Installation professionnelle</li>
            <li>Finitions haut de gamme</li>
            <li>Service après-vente</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head'

export default {
  name: 'CommerceLoisirs',
  setup() {
    useHead({
      title: 'Commerce & Loisirs - CIBAO Solutions Design',
      meta: [
        { name: 'description', content: 'Solutions de chaudronnerie pour les secteurs du commerce et des loisirs. Structures design et durables. Expert à Saint-Nazaire.' },
        { name: 'keywords', content: 'chaudronnerie commerce, aménagement loisirs, structures design, Saint-Nazaire' },
        { property: 'og:title', content: 'Solutions Commerce & Loisirs - CIBAO' },
        { property: 'og:description', content: 'Expert en solutions de chaudronnerie pour le commerce et les loisirs' },
        { property: 'og:url', content: 'https://cibao.fr/commerce-loisirs' }
      ]
    })
  }
}
</script>
