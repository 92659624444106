<template>
  <div class="bg-gray-100">
    <div class="container mx-auto px-4 py-12">
      <h1 class="text-4xl font-bold text-blue-900 mb-8 text-center">Développez votre carrière avec nous</h1>

      <div class="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-xl">
        <h2 class="text-xl font-bold mb-6 text-gray-800">Envoyez nous votre demande et votre CV</h2>
        <form @submit.prevent="submitForm">
          <div class="mb-4">
            <label for="name" class="block text-gray-700 text-sm font-bold mb-2">Nom :</label>
            <input type="text" id="name" v-model="formData.name" required
                   class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500">
          </div>
          <div class="mb-4">
            <label for="email" class="block text-gray-700 text-sm font-bold mb-2">Email :</label>
            <input type="email" id="email" v-model="formData.email" required
                   class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500">
          </div>
          <div class="mb-4">
            <label for="phone" class="block text-gray-700 text-sm font-bold mb-2">Téléphone :</label>
            <input type="tel" id="phone" v-model="formData.phone" required
                   class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500">
          </div>
          <div class="mb-4">
            <label for="file" class="block text-gray-700 text-sm font-bold mb-2">CV :</label>
            <input type="file" id="file" @change="handleFileUpload"
                   class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500">
          </div>
          <div class="mb-6">
            <label for="message" class="block text-gray-700 text-sm font-bold mb-2">Message :</label>
            <textarea id="message" v-model="formData.message" required
                     class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-32"></textarea>
          </div>
          <button type="submit"
                  class="w-full bg-blue-500 text-white font-bold py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
            Envoyer
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head'
import { ref } from 'vue'

export default {
  name: 'RecrutementView',
  setup() {
    useHead({
      title: 'Recrutement - Rejoignez CIBAO',
      meta: [
        { name: 'description', content: 'Rejoignez CIBAO, expert en chaudronnerie et tuyauterie industrielle. Opportunités de carrière à Saint-Nazaire.' },
        { name: 'keywords', content: 'recrutement CIBAO, emploi chaudronnerie, carrière tuyauterie, Saint-Nazaire' },
        { property: 'og:title', content: 'Carrières - CIBAO' },
        { property: 'og:description', content: 'Opportunités de carrière chez CIBAO' },
        { property: 'og:url', content: 'https://cibao.fr/recrutement' }
      ]
    })

    const formData = ref({
      name: '',
      email: '',
      phone: '',
      message: '',
      file: null
    })

    const handleFileUpload = (event) => {
      formData.value.file = event.target.files[0]
    }

    const submitForm = () => {
      console.log('Form submitted:', formData.value)
      // Réinitialiser le formulaire
      formData.value = {
        name: '',
        email: '',
        phone: '',
        message: '',
        file: null
      }
    }

    return {
      formData,
      handleFileUpload,
      submitForm
    }
  }
}
</script>

<style scoped>
.contact {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input, textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

textarea {
  height: 100px;
}

button {
  background-color: #00519e;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #003d7a;
}
</style>
