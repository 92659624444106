<template>
  <div class="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-xl">
    <h1 class="text-2xl font-bold mb-6 text-gray-800">Contactez-nous</h1>
    <form @submit.prevent="submitForm">
      <div class="mb-4">
        <label for="name" class="block text-gray-700 text-sm font-bold mb-2">Nom :</label>
        <input type="text" id="name" v-model="form.name" required
               class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500">
      </div>
      <div class="mb-4">
        <label for="email" class="block text-gray-700 text-sm font-bold mb-2">Email :</label>
        <input type="email" id="email" v-model="form.email" required
               class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500">
      </div>
      <div class="mb-4">
        <label for="phone" class="block text-gray-700 text-sm font-bold mb-2">Téléphone :</label>
        <input type="phone" id="phone" v-model="form.phone" required
               class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500">
      </div>
      <div class="mb-6">
        <label for="message" class="block text-gray-700 text-sm font-bold mb-2">Message :</label>
        <textarea id="message" v-model="form.message" required
                  class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-32"></textarea>
      </div>
      <button type="submit"
              class="w-full bg-blue-500 text-white font-bold py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
        Envoyer
      </button>
    </form>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head'

export default {
  name: 'ContactView',
  data () {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        message: '',
        file: null
      }
    }
  },
  methods: {
    handleFileUpload(event) {
      this.form.file = event.target.files[0]
    },
    submitForm () {
      console.log('Formulaire soumis', this.form)
      // Réinitialiser le formulaire après soumission
      this.form = { name: '', email: '', phone: '', message: '', file: null }
    }
  },
  setup() {
    useHead({
      title: 'Contact - CIBAO Tuyauterie Industrielle',
      meta: [
        { name: 'description', content: 'Contactez CIBAO pour vos projets de tuyauterie industrielle et chaudronnerie à Saint-Nazaire. Devis gratuit et conseil personnalisé.' },
        { name: 'keywords', content: 'contact CIBAO, devis tuyauterie, Saint-Nazaire' },
        // ... autres métadonnées adaptées
      ]
    })
  }
}
</script>

<style scoped>
.contact {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input, textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

textarea {
  height: 100px;
}

button {
  background-color: #00519e;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #003d7a;
}
</style>
