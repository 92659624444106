<template>
  <div class="max-w-3xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-xl">
    <h1 class="text-3xl font-bold mb-6 text-gray-800 text-center">Mentions Légales</h1>
    <div class="space-y-6">
      <div class="flex items-center">
        <i class="fas fa-file-alt text-blue-600 text-2xl mr-4"></i>
        <div>
          <h2 class="text-xl font-semibold">Statut</h2>
          <p>Forme juridique : SASU Société par actions simplifiée à associé unique au capital de 3000€ </p>
        </div>
      </div>
      <div class="flex items-center">
        <i class="fas fa-building text-blue-600 text-2xl mr-4"></i>
        <div>
          <h2 class="text-xl font-semibold">Nom commerciale</h2>
          <p>CIBAO</p>
        </div>
      </div>
      <div class="flex items-center">
        <i class="fas fa-phone text-blue-600 text-2xl mr-4"></i>
        <div>
          <h2 class="text-xl font-semibold">Téléphone</h2>
          <p>+33 228 546 027 </p>
        </div>
      </div>
      <div class="flex items-center">
        <i class="fas fa-map-marker-alt text-blue-600 text-2xl mr-4"></i>
        <div>
          <h2 class="text-xl font-semibold">Adresse postale</h2>
          <p>5 rue de Trignac, 44600 SAINT-NAZAIRE</p>
        </div>
      </div>
      <div class="flex items-center">
        <i class="fas fa-id-card text-blue-600 text-2xl mr-4"></i>
        <div>
          <h2 class="text-xl font-semibold">Identification</h2>
          <p>Numéro SIRET (siège) : 91252301600018</p>
          <p>Numéro TVA Intracommunautaire : FR43912523016</p>
          <p>Numéro RCS : Saint-Nazaire B 912 523 016</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head'

export default {
  name: 'MentionsLegaleView',
  setup() {
    useHead({
      title: 'Mentions Légales - CIBAO',
      meta: [
        { name: 'description', content: 'Mentions légales de CIBAO, SASU au capital de 3000€. Informations légales, conditions d\'utilisation et politique de confidentialité.' },
        { name: 'robots', content: 'noindex, nofollow' },
        { property: 'og:title', content: 'Mentions Légales - CIBAO' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: 'https://cibao.fr/mentions-legales' }
      ]
    })
  }
}
</script>

<style scoped>
/* Ajoutez des styles spécifiques ici si nécessaire */
</style>
