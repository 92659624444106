<template>
  <div class="container mx-auto px-4 py-8">
    <h1 class="text-3xl font-bold text-gray-800 mb-4">Tuyauterie au service de la défense et de la sécurité</h1>
    
    <div class="grid md:grid-cols-2 gap-8 mb-12">
      <div>
        <p class="text-lg text-gray-600 mb-6">
          Dans les secteurs de la défense et de la sécurité, la fiabilité des équipements est cruciale. Nous concevons et fabriquons des structures métalliques sur mesure, robustes et durables, répondant aux exigences les plus strictes en matière de sécurité.
        </p>
        <p class="text-lg text-gray-600 mb-6">
          Nos solutions comprennent :
        </p>
        <ul class="list-disc list-inside text-lg text-gray-600 mb-6 space-y-2">
          <li>Structures de protection balistique</li>
          <li>Équipements tactiques spécialisés</li>
          <li>Systèmes de sécurisation</li>
          <li>Conteneurs sécurisés</li>
          <li>Infrastructures de défense</li>
        </ul>
      </div>
      <div class="h-[400px]">
        <img src="@/assets/images/image_cibao.jpg" alt="Sécurité" class="rounded-lg shadow-lg w-full h-full object-cover">
      </div>
    </div>

    <div class="bg-gray-50 rounded-lg p-8 mb-12">
      <h2 class="text-2xl font-bold text-gray-800 mb-6">Notre expertise sécurité</h2>
      <div class="grid md:grid-cols-3 gap-6">
        <div class="bg-white p-6 rounded-lg shadow">
          <h3 class="text-xl font-semibold mb-4">Confidentialité</h3>
          <p class="text-gray-600">Respect des protocoles de sécurité et de confidentialité les plus stricts.</p>
        </div>
        <div class="bg-white p-6 rounded-lg shadow">
          <h3 class="text-xl font-semibold mb-4">Certifications</h3>
          <p class="text-gray-600">Conformité aux normes de défense et accréditations sécuritaires requises.</p>
        </div>
        <div class="bg-white p-6 rounded-lg shadow">
          <h3 class="text-xl font-semibold mb-4">Fiabilité</h3>
          <p class="text-gray-600">Tests rigoureux et validation de tous les équipements avant livraison.</p>
        </div>
      </div>
    </div>

    <div class="grid md:grid-cols-2 gap-8 mb-12">
      <div class="h-[400px]">
        <img src="@/assets/images/image_cibao.jpg" alt="Défense" class="rounded-lg shadow-lg w-full h-full object-cover">
      </div>
      <div>
        <h2 class="text-2xl font-bold text-gray-800 mb-6">Domaines d'application</h2>
        <p class="text-lg text-gray-600 mb-6">
          Nos solutions s'adaptent à différents contextes :
        </p>
        <ul class="list-disc list-inside text-lg text-gray-600 space-y-2">
          <li>Forces armées</li>
          <li>Services de sécurité</li>
          <li>Sites sensibles</li>
          <li>Infrastructures critiques</li>
          <li>Transport sécurisé</li>
        </ul>
      </div>
    </div>

    <div class="bg-blue-50 rounded-lg p-8">
      <h2 class="text-2xl font-bold text-gray-800 mb-6">Services spécialisés</h2>
      <div class="grid md:grid-cols-2 gap-8">
        <div>
          <h3 class="text-xl font-semibold mb-4">Conception sécurisée</h3>
          <p class="text-gray-600 mb-4">
            Développement de solutions sur mesure avec un niveau de sécurité optimal.
          </p>
          <ul class="list-disc list-inside text-gray-600">
            <li>Études techniques avancées</li>
            <li>Prototypage sécurisé</li>
            <li>Tests de résistance</li>
          </ul>
        </div>
        <div>
          <h3 class="text-xl font-semibold mb-4">Maintenance spécialisée</h3>
          <p class="text-gray-600 mb-4">
            Services de maintenance adaptés aux équipements de défense.
          </p>
          <ul class="list-disc list-inside text-gray-600">
            <li>Maintenance préventive</li>
            <li>Interventions d'urgence</li>
            <li>Mises à niveau sécuritaires</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head'

export default {
  name: 'DefenseSecurite',
  setup() {
    useHead({
      title: 'Défense & Sécurité - CIBAO Solutions Robustes',
      meta: [
        { name: 'description', content: 'Solutions de chaudronnerie pour les secteurs de la défense et de la sécurité. Équipements robustes et fiables. Expert à Saint-Nazaire.' },
        { name: 'keywords', content: 'chaudronnerie défense, équipements sécurité, structures robustes, Saint-Nazaire' },
        { property: 'og:title', content: 'Solutions Défense & Sécurité - CIBAO' },
        { property: 'og:description', content: 'Expert en solutions de chaudronnerie pour la défense et la sécurité' },
        { property: 'og:url', content: 'https://cibao.fr/defense-securite' }
      ]
    })
  }
}
</script>
