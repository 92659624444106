<template>
  <header class="bg-gradient-to-r from-blue-900 to-blue-700 text-white relative z-50">
    <div class="container mx-auto px-4 py-2 flex justify-between items-center">
      <a href="/espace-client" class="text-white hover:text-blue-300 transition duration-300">
        <i class="fas fa-user-circle mr-2"></i>Espace client
      </a>
      <a href="tel:+33228546027 " class="text-white hover:text-blue-300 transition duration-300">
        <i class="fas fa-phone-alt mr-2"></i>+33 228 546 027 
      </a>
    </div>
    <nav class="container mx-auto px-4 py-4">
      <div class="flex flex-wrap justify-between items-center">
        <router-link to="/" class="flex items-center">
          <img src="@/assets/images/logo.png" alt="CIBAO" class="h-12 mr-3">        
        </router-link>
        <div class="flex items-center space-x-6">
          <router-link to="/" class="hover:text-blue-300 transition duration-300">Accueil</router-link>
          <div class="relative group">
            <button @click.stop="toggleMenu" class="hover:text-blue-300 flex items-center transition duration-300">
              Services
              <svg class="w-4 h-4 ml-1 transition-transform duration-300 transform group-hover:rotate-180" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
            </button>
            <div v-if="isMenuOpen" class="absolute left-0 mt-2 w-64 bg-white text-gray-800 shadow-lg rounded-lg py-2 px-4 z-50">
              <router-link v-for="service in services" :key="service.path" :to="service.path" class="block py-2 hover:text-blue-900 transition duration-300">
                {{ service.name }}
              </router-link>
            </div>
          </div>
          <router-link to="/qui-sommes-nous" class="hover:text-blue-300 transition duration-300">Qui sommes-nous</router-link>
          <router-link to="/nos-bureaux" class="hover:text-blue-300 transition duration-300">Nos bureaux</router-link>
          <router-link to="/recrutement" class="hover:text-blue-300 transition duration-300">Recrutement</router-link>
          <router-link to="/contact" class="hover:text-blue-300 transition duration-300">Contact</router-link>
        </div>
        <button @click="toggleMobileMenu" class="lg:hidden">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7"></path></svg>
        </button>
      </div>
    </nav>
    <div v-if="mobileMenuOpen" class="lg:hidden">
      <ul class="bg-blue-800 py-2">
        <li><router-link to="/" class="block px-4 py-2 hover:bg-blue-700 transition duration-300">Accueil</router-link></li>
        <li v-for="service in services" :key="service.path">
          <router-link :to="service.path" class="block px-4 py-2 hover:bg-blue-700 transition duration-300">{{ service.name }}</router-link>
        </li>
        <li><router-link to="/qui-sommes-nous" class="block px-4 py-2 hover:bg-blue-700 transition duration-300">Qui sommes-nous</router-link></li>
        <li><router-link to="/contact" class="block px-4 py-2 hover:bg-blue-700 transition duration-300">Contact</router-link></li>
        <li><a href="/espace-client" class="block px-4 py-2 hover:bg-blue-700 transition duration-300">Espace client</a></li>
      </ul>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderComponent',
  data() {
    return {
      mobileMenuOpen: false,
      isMenuOpen: false,
      services: [
        { name: 'Industrie', path: '/industrie' },
        { name: 'Agroalimentaire', path: '/agroalimentaire' },
        { name: 'Énergie et Nucléaire', path: '/energie-nucleaire' },
        { name: 'Bâtiment', path: '/batiment' },
        { name: 'Naval', path: '/naval' },
        { name: 'Pharmaceutique', path: '/pharmaceutique' },
        { name: 'Transports', path: '/transports' },
        { name: 'Défense et Sécurité', path: '/defense-securite' },
        { name: 'Électronique et Télécom', path: '/electronique-telecom' },
        { name: 'Commerce et Loisirs', path: '/commerce-loisirs' }
      ]
    }
  },
  methods: {
    toggleMobileMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen
    },
    toggleMenu(event) {
      event.stopPropagation()
      this.isMenuOpen = !this.isMenuOpen
    },
    closeMenu() {
      this.isMenuOpen = false
    }
  },
  mounted() {
    document.addEventListener('click', this.closeMenu)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeMenu)
  }
}
</script>

<style scoped>
.group:hover .absolute {
  display: block;
}
</style>
