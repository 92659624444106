<template>
  <div>
    <div class="container mx-auto px-4 py-12">
      <h1 class="text-4xl font-bold text-blue-900 mb-8 text-center">Qui sommes-nous ?</h1>
      
      <div class="bg-white shadow-lg rounded-lg overflow-hidden mb-12">
        <div class="md:flex">
          <div class="md:w-1/2 p-6">
            <h2 class="text-3xl font-bold text-blue-800 mb-4">CIBAO</h2>
            <p class="text-lg text-gray-600 mb-4">
              Avec plus de 20 ans d'expérience, CIBAO s'est imposé comme un leader dans le domaine de la tuyauterie industrielle. Notre expertise et notre engagement envers l'excellence nous ont permis de devenir un partenaire de confiance pour de nombreux secteurs industriels.
            </p>
            <p class="text-lg text-gray-600">
              Chez CIBAO, nous croyons en l'importance de l'écoute client, de la qualité du travail et du respect des délais. Notre mission est de contribuer au succès de nos clients en leur fournissant des équipements fiables et performants.
            </p>
          </div>
          <div class="md:w-1/2 bg-blue-900 flex items-center justify-center">
            <img src="@/assets/images/image_cibao.jpg" alt="CIBAO" class="object-cover h-full w-full">
          </div>
        </div>
      </div>

      <div class="text-center p-6">
            <h2 class="text-3xl font-bold text-blue-800 mb-4">Notre atout majeur : un réseau de prestataires qualifiés et disponibles</h2>
            <p class="text-lg text-gray-600 mb-12">
              Nous mettons à votre disposition une équipe de prestataires hautement qualifiés, sélectionnés pour leur expertise technique et leur capacité à répondre aux exigences les plus strictes. Notre force réside dans la disponibilité et la réactivité de ces professionnels, garantissant des solutions adaptées à vos projets. Collaborer avec nous, c'est avoir l'assurance de travailler avec des experts engagés, capables de délivrer des prestations de qualité supérieure, dans les délais impartis.
            </p>
          </div>

      <div class="grid md:grid-cols-2 gap-8 mb-12">
        <div class="bg-white shadow-lg rounded-lg overflow-hidden">
          <div class="p-6">
            <h2 class="text-2xl font-bold text-blue-800 mb-4">Des experts, de la réalisation à la maintenance</h2>
            <p class="text-gray-600 mb-4">
              CIBAO excelle dans la réalisation, la modification et la maintenance de tuyauteries industrielles. Nous optimisons vos process de transport de fluides, de solides et de gaz, en respectant les normes les plus strictes.
            </p>
            <h3 class="text-xl font-semibold mb-2">Nos réalisations :</h3>
            <ul class="list-disc list-inside text-gray-600">
              <li>Réseaux de process industriel (fluides, solides, gaz)</li>
              <li>Réalisation isométriques sur format DWG</li>
            </ul>
          </div>
        </div>
        <div class="bg-white shadow-lg rounded-lg overflow-hidden">
          <div class="p-6">
            <h2 class="text-2xl font-bold text-blue-800 mb-4">La création de vos équipements sur mesure</h2>
            <p class="text-gray-600 mb-4">
              CIBAO réalise en atelier toutes vos pièces industrielle et les monte sur site pour une installation clé en main. Nous intervenons de la conception à l'installation, en veillant au respect des normes et codes de fabrication.
            </p>
            <h3 class="text-xl font-semibold mb-2">Nos applications :</h3>
            <ul class="list-disc list-inside text-gray-600">
              <li>Tuyauteries industrielles</li>
              <li>Conception métal sur mesure en volume (ex : mobilier sur mesure en métal)</li>
              <li>Découpage métal au laser</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="bg-white shadow-lg rounded-lg overflow-hidden">
        <div class="p-6">
          <h2 class="text-2xl font-bold text-blue-800 mb-8">Notre processus de travail</h2>
          <div class="grid md:grid-cols-3 gap-4">
            <div class="text-center">
              <div class="bg-blue-100 rounded-full w-20 h-20 flex items-center justify-center mx-auto mb-4">
                <i class="fas fa-lightbulb text-3xl text-blue-800"></i>
              </div>
              <h5 class="font-semibold mb-2">Conception</h5>
              <p class="text-gray-600">Analyse et recherche de solutions techniques optimales</p>
            </div>
            <div class="text-center">
              <div class="bg-blue-100 rounded-full w-20 h-20 flex items-center justify-center mx-auto mb-4">
                <i class="fas fa-tools text-3xl text-blue-800"></i>
              </div>
              <h5 class="font-semibold mb-2">Fabrication</h5>
              <p class="text-gray-600">Réalisation dans notre atelier de 1000 m² avec équipement de pointe</p>
            </div>
            <div class="text-center">
              <div class="bg-blue-100 rounded-full w-20 h-20 flex items-center justify-center mx-auto mb-4">
                <i class="fas fa-truck text-3xl text-blue-800"></i>
              </div>
              <h5 class="font-semibold mb-2">Installation</h5>
              <p class="text-gray-600">Mise en œuvre sur site par nos équipes qualifiées</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head'

export default {
  name: 'QuiSommesNousView',
  setup() {
    useHead({
      title: 'Qui sommes-nous ? - CIBAO',
      meta: [
        { name: 'description', content: 'Découvrez CIBAO, expert en chaudronnerie et tuyauterie industrielle à Saint-Nazaire depuis plus de 20 ans. Notre expertise, nos valeurs et notre engagement.' },
        { name: 'keywords', content: 'CIBAO, chaudronnerie Saint-Nazaire, tuyauterie industrielle, expertise, qualité' },
        { property: 'og:title', content: 'À propos de CIBAO' },
        { property: 'og:description', content: 'Expert en chaudronnerie et tuyauterie industrielle depuis plus de 20 ans' },
        { property: 'og:url', content: 'https://cibao.fr/qui-sommes-nous' }
      ]
    })
  }
}
</script>
