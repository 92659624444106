<template>
  <div class="container mx-auto px-4 py-8">
    <h1 class="text-3xl font-bold text-gray-800 mb-4">Tuyauterie pour l'industrie électronique et télécom</h1>
    
    <div class="grid md:grid-cols-2 gap-8 mb-12">
      <div>
        <p class="text-lg text-gray-600 mb-6">
          L'industrie électronique et des télécoms nécessite des structures métalliques précises et résistantes aux interférences. Notre expertise nous permet de concevoir et fabriquer des équipements répondant aux exigences spécifiques de ces secteurs de pointe.
        </p>
        <p class="text-lg text-gray-600 mb-6">
          Nos solutions comprennent :
        </p>
        <ul class="list-disc list-inside text-lg text-gray-600 mb-6 space-y-2">
          <li>Armoires et baies de serveurs</li>
          <li>Boîtiers blindés EMI/RFI</li>
          <li>Structures pour antennes</li>
          <li>Coffrets techniques</li>
          <li>Supports d'équipements</li>
        </ul>
      </div>
      <div class="h-[400px]">
        <img src="@/assets/images/image_cibao.jpg" alt="Électronique" class="rounded-lg shadow-lg w-full h-full object-cover">
      </div>
    </div>

    <div class="bg-gray-50 rounded-lg p-8 mb-12">
      <h2 class="text-2xl font-bold text-gray-800 mb-6">Notre expertise technique</h2>
      <div class="grid md:grid-cols-3 gap-6">
        <div class="bg-white p-6 rounded-lg shadow">
          <h3 class="text-xl font-semibold mb-4">Protection EMI</h3>
          <p class="text-gray-600">Solutions de blindage électromagnétique pour la protection des équipements sensibles.</p>
        </div>
        <div class="bg-white p-6 rounded-lg shadow">
          <h3 class="text-xl font-semibold mb-4">Précision</h3>
          <p class="text-gray-600">Fabrication de haute précision pour les composants électroniques et télécoms.</p>
        </div>
        <div class="bg-white p-6 rounded-lg shadow">
          <h3 class="text-xl font-semibold mb-4">Innovation</h3>
          <p class="text-gray-600">Utilisation des dernières technologies pour des solutions optimales.</p>
        </div>
      </div>
    </div>

    <div class="grid md:grid-cols-2 gap-8 mb-12">
      <div class="h-[400px]">
        <img src="@/assets/images/image_cibao.jpg" alt="Télécom" class="rounded-lg shadow-lg w-full h-full object-cover">
      </div>
      <div>
        <h2 class="text-2xl font-bold text-gray-800 mb-6">Applications spécifiques</h2>
        <p class="text-lg text-gray-600 mb-6">
          Nos solutions s'adaptent à différents domaines :
        </p>
        <ul class="list-disc list-inside text-lg text-gray-600 space-y-2">
          <li>Centres de données</li>
          <li>Stations de télécommunication</li>
          <li>Laboratoires électroniques</li>
          <li>Salles de contrôle</li>
          <li>Installations de test</li>
        </ul>
      </div>
    </div>

    <div class="bg-blue-50 rounded-lg p-8">
      <h2 class="text-2xl font-bold text-gray-800 mb-6">Services spécialisés</h2>
      <div class="grid md:grid-cols-2 gap-8">
        <div>
          <h3 class="text-xl font-semibold mb-4">Conception sur mesure</h3>
          <p class="text-gray-600 mb-4">
            Solutions personnalisées pour vos besoins spécifiques.
          </p>
          <ul class="list-disc list-inside text-gray-600">
            <li>Études de compatibilité EMC</li>
            <li>Prototypage rapide</li>
            <li>Tests de validation</li>
          </ul>
        </div>
        <div>
          <h3 class="text-xl font-semibold mb-4">Installation et maintenance</h3>
          <p class="text-gray-600 mb-4">
            Services complets pour vos équipements.
          </p>
          <ul class="list-disc list-inside text-gray-600">
            <li>Installation sur site</li>
            <li>Maintenance préventive</li>
            <li>Support technique</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head'

export default {
  name: 'ElectroniqueTelecom',
  setup() {
    useHead({
      title: 'Électronique & Télécom - CIBAO Solutions Précises',
      meta: [
        { name: 'description', content: 'Solutions de chaudronnerie pour l\'industrie électronique et télécom. Structures précises et résistantes aux interférences. Expert à Saint-Nazaire.' },
        { name: 'keywords', content: 'chaudronnerie électronique, structures télécom, équipements précis, Saint-Nazaire' },
        { property: 'og:title', content: 'Solutions Électronique & Télécom - CIBAO' },
        { property: 'og:description', content: 'Expert en solutions de chaudronnerie pour l\'électronique et les télécoms' },
        { property: 'og:url', content: 'https://cibao.fr/electronique-telecom' }
      ]
    })
  }
}
</script>
