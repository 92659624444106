<template>
  <div class="container mx-auto px-4 py-8">
    <h1 class="text-3xl font-bold text-gray-800 mb-4">Tuyauterie pour le secteur de l'énergie et du nucléaire</h1>
    
    <div class="grid md:grid-cols-2 gap-8 mb-12">
      <div>
        <p class="text-lg text-gray-600 mb-6">
          Dans le secteur de l'énergie, et particulièrement du nucléaire, la sécurité et la fiabilité des équipements sont primordiales. Notre expertise nous permet de répondre aux normes les plus strictes de l'industrie nucléaire.
        </p>
        <p class="text-lg text-gray-600 mb-6">
          Nous intervenons dans la conception et la fabrication d'équipements critiques :
        </p>
        <ul class="list-disc list-inside text-lg text-gray-600 mb-6 space-y-2">
          <li>Tuyauteries haute pression</li>
          <li>Échangeurs thermiques</li>
          <li>Cuves de confinement</li>
          <li>Systèmes de refroidissement</li>
          <li>Structures de support</li>
        </ul>
      </div>
      <div class="h-[400px]">
        <img src="@/assets/images/image_cibao.jpg" alt="Énergie" class="rounded-lg shadow-lg w-full h-full object-cover">
      </div>
    </div>

    <div class="bg-gray-50 rounded-lg p-8 mb-12">
      <h2 class="text-2xl font-bold text-gray-800 mb-6">Certifications et normes</h2>
      <div class="grid md:grid-cols-3 gap-6">
        <div class="bg-white p-6 rounded-lg shadow">
          <h3 class="text-xl font-semibold mb-4">Sécurité nucléaire</h3>
          <p class="text-gray-600">Respect des normes ASN et des réglementations internationales en matière de sûreté nucléaire.</p>
        </div>
        <div class="bg-white p-6 rounded-lg shadow">
          <h3 class="text-xl font-semibold mb-4">Qualité certifiée</h3>
          <p class="text-gray-600">Certifications ISO 9001, EN 1090 et autres normes spécifiques au secteur nucléaire.</p>
        </div>
        <div class="bg-white p-6 rounded-lg shadow">
          <h3 class="text-xl font-semibold mb-4">Traçabilité</h3>
          <p class="text-gray-600">Documentation complète et suivi rigoureux de tous les processus de fabrication.</p>
        </div>
      </div>
    </div>

    <div class="grid md:grid-cols-2 gap-8 mb-12">
      <div class="h-[400px]">
        <img src="@/assets/images/image_cibao.jpg" alt="Installation Énergétique" class="rounded-lg shadow-lg w-full h-full object-cover">
      </div>
      <div>
        <h2 class="text-2xl font-bold text-gray-800 mb-6">Expertise technique</h2>
        <p class="text-lg text-gray-600 mb-6">
          Notre équipe hautement qualifiée maîtrise les techniques spécifiques au secteur nucléaire :
        </p>
        <ul class="list-disc list-inside text-lg text-gray-600 space-y-2">
          <li>Soudage qualifié nucléaire</li>
          <li>Contrôles non destructifs</li>
          <li>Assemblage de précision</li>
          <li>Tests sous pression</li>
          <li>Maintenance spécialisée</li>
        </ul>
      </div>
    </div>

    <div class="bg-blue-50 rounded-lg p-8">
      <h2 class="text-2xl font-bold text-gray-800 mb-6">Domaines d'intervention</h2>
      <div class="grid md:grid-cols-2 gap-8">
        <div>
          <h3 class="text-xl font-semibold mb-4">Centrales nucléaires</h3>
          <p class="text-gray-600 mb-4">
            Intervention sur les circuits primaires et secondaires, maintenance des équipements critiques.
          </p>
          <ul class="list-disc list-inside text-gray-600">
            <li>Circuits de refroidissement</li>
            <li>Systèmes de sécurité</li>
            <li>Équipements sous pression</li>
          </ul>
        </div>
        <div>
          <h3 class="text-xl font-semibold mb-4">Énergies alternatives</h3>
          <p class="text-gray-600 mb-4">
            Solutions pour les secteurs des énergies renouvelables et alternatives.
          </p>
          <ul class="list-disc list-inside text-gray-600">
            <li>Biomasse</li>
            <li>Hydrogène</li>
            <li>Systèmes hybrides</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head'

export default {
  name: 'EnergieNucleaire',
  setup() {
    useHead({
      title: 'Énergie & Nucléaire - CIBAO Solutions Industrielles',
      meta: [
        { name: 'description', content: 'Solutions de chaudronnerie et tuyauterie pour le secteur de l\'énergie et du nucléaire. Équipements haute sécurité. Expert à Saint-Nazaire.' },
        { name: 'keywords', content: 'chaudronnerie nucléaire, tuyauterie énergie, équipements nucléaires, Saint-Nazaire' },
        { property: 'og:title', content: 'Solutions Énergie & Nucléaire - CIBAO' },
        { property: 'og:description', content: 'Expert en solutions de chaudronnerie pour l\'énergie et le nucléaire' },
        { property: 'og:url', content: 'https://cibao.fr/energie-nucleaire' }
      ]
    })
  }
}
</script>
