<template>
  <div class="container mx-auto px-4 py-8">
    <h1 class="text-3xl font-bold text-gray-800 mb-4">Tuyauterie pour l'industrie navale</h1>
    
    <div class="grid md:grid-cols-2 gap-8 mb-12">
      <div>
        <p class="text-lg text-gray-600 mb-6">
          Dans l'industrie navale, la résistance à la corrosion et aux conditions marines est essentielle. Notre expertise en chaudronnerie navale nous permet de réaliser des équipements robustes et durables pour tous types de navires.
        </p>
        <p class="text-lg text-gray-600 mb-6">
          Nos réalisations comprennent :
        </p>
        <ul class="list-disc list-inside text-lg text-gray-600 mb-6 space-y-2">
          <li>Tuyauteries marines spécialisées</li>
          <li>Équipements de pont</li>
          <li>Structures en acier inoxydable</li>
          <li>Systèmes de ventilation marine</li>
          <li>Réservoirs et ballasts</li>
        </ul>
      </div>
      <div class="h-[400px]">
        <img src="@/assets/images/image_cibao.jpg" alt="Construction Navale" class="rounded-lg shadow-lg w-full h-full object-cover">
      </div>
    </div>

    <div class="bg-gray-50 rounded-lg p-8 mb-12">
      <h2 class="text-2xl font-bold text-gray-800 mb-6">Notre expertise marine</h2>
      <div class="grid md:grid-cols-3 gap-6">
        <div class="bg-white p-6 rounded-lg shadow">
          <h3 class="text-xl font-semibold mb-4">Certifications</h3>
          <p class="text-gray-600">Conformité aux normes maritimes internationales et certifications des sociétés de classification.</p>
        </div>
        <div class="bg-white p-6 rounded-lg shadow">
          <h3 class="text-xl font-semibold mb-4">Matériaux</h3>
          <p class="text-gray-600">Utilisation de matériaux spécialement traités pour résister aux environnements marins.</p>
        </div>
        <div class="bg-white p-6 rounded-lg shadow">
          <h3 class="text-xl font-semibold mb-4">Installation</h3>
          <p class="text-gray-600">Équipes expérimentées pour l'installation à bord et les interventions en mer.</p>
        </div>
      </div>
    </div>

    <div class="grid md:grid-cols-2 gap-8 mb-12">
      <div class="h-[400px]">
        <img src="@/assets/images/image_cibao.jpg" alt="Chantier Naval" class="rounded-lg shadow-lg w-full h-full object-cover">
      </div>
      <div>
        <h2 class="text-2xl font-bold text-gray-800 mb-6">Types d'interventions</h2>
        <p class="text-lg text-gray-600 mb-6">
          Nous intervenons sur différents types de navires et installations :
        </p>
        <ul class="list-disc list-inside text-lg text-gray-600 space-y-2">
          <li>Navires de commerce</li>
          <li>Bateaux de pêche</li>
          <li>Navires militaires</li>
          <li>Plateformes offshore</li>
          <li>Yachts et bateaux de plaisance</li>
        </ul>
      </div>
    </div>

    <div class="bg-blue-50 rounded-lg p-8">
      <h2 class="text-2xl font-bold text-gray-800 mb-6">Services spécialisés</h2>
      <div class="grid md:grid-cols-2 gap-8">
        <div>
          <h3 class="text-xl font-semibold mb-4">Maintenance et réparation</h3>
          <p class="text-gray-600 mb-4">
            Services complets de maintenance et réparation pour tous types d'équipements marins.
          </p>
          <ul class="list-disc list-inside text-gray-600">
            <li>Réparations d'urgence</li>
            <li>Maintenance préventive</li>
            <li>Modernisation d'équipements</li>
          </ul>
        </div>
        <div>
          <h3 class="text-xl font-semibold mb-4">Solutions sur mesure</h3>
          <p class="text-gray-600 mb-4">
            Conception et fabrication d'équipements spécifiques selon vos besoins.
          </p>
          <ul class="list-disc list-inside text-gray-600">
            <li>Études techniques</li>
            <li>Prototypage</li>
            <li>Installation à bord</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head'

export default {
  name: 'NavalView',
  setup() {
    useHead({
      title: 'Naval - CIBAO Chaudronnerie Marine',
      meta: [
        { name: 'description', content: 'Solutions de chaudronnerie et tuyauterie pour l\'industrie navale. Équipements résistants aux conditions marines. Expert à Saint-Nazaire.' },
        { name: 'keywords', content: 'chaudronnerie navale, tuyauterie marine, équipements navals, Saint-Nazaire' },
        { property: 'og:title', content: 'Solutions Navales - CIBAO' },
        { property: 'og:description', content: 'Expert en solutions de chaudronnerie pour l\'industrie navale' },
        { property: 'og:url', content: 'https://cibao.fr/naval' }
      ]
    })
  }
}
</script>
