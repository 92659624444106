<template>
  <div class="container mx-auto px-4 py-8">
    <h1 class="text-3xl font-bold text-gray-800 mb-4">Tuyauterie pour l'agroalimentaire</h1>
    
    <div class="grid md:grid-cols-2 gap-8 mb-12">
      <div>
        <p class="text-lg text-gray-600 mb-6">
          Dans le secteur agroalimentaire, les normes sanitaires et de sécurité sont essentielles. Nous fournissons des équipements en inox et autres matériaux résistants pour répondre aux exigences strictes de ce domaine.
        </p>
        <p class="text-lg text-gray-600 mb-6">
          Nos solutions respectent les normes HACCP et sont conçues pour garantir une hygiène optimale dans vos processus de production alimentaire.
        </p>
        <ul class="list-disc list-inside text-lg text-gray-600 mb-6 space-y-2">
          <li>Cuves de stockage alimentaire</li>
          <li>Systèmes de tuyauterie sanitaire</li>
          <li>Équipements de transformation</li>
          <li>Tables et plans de travail inox</li>
          <li>Systèmes de filtration</li>
        </ul>
      </div>
      <div class="h-[400px]">
        <img src="@/assets/images/image_cibao.jpg" alt="Industrie Agroalimentaire" class="rounded-lg shadow-lg w-full h-full object-cover">
      </div>
    </div>

    <div class="bg-gray-50 rounded-lg p-8 mb-12">
      <h2 class="text-2xl font-bold text-gray-800 mb-6">Nos engagements qualité</h2>
      <div class="grid md:grid-cols-3 gap-6">
        <div class="bg-white p-6 rounded-lg shadow">
          <h3 class="text-xl font-semibold mb-4">Normes sanitaires</h3>
          <p class="text-gray-600">Respect strict des normes d'hygiène et de sécurité alimentaire (HACCP, ISO 22000).</p>
        </div>
        <div class="bg-white p-6 rounded-lg shadow">
          <h3 class="text-xl font-semibold mb-4">Matériaux certifiés</h3>
          <p class="text-gray-600">Utilisation exclusive d'inox alimentaire et de matériaux approuvés pour le contact alimentaire.</p>
        </div>
        <div class="bg-white p-6 rounded-lg shadow">
          <h3 class="text-xl font-semibold mb-4">Traçabilité</h3>
          <p class="text-gray-600">Suivi complet des matériaux et des processus de fabrication.</p>
        </div>
      </div>
    </div>

    <div class="grid md:grid-cols-2 gap-8 mb-12">
      <div class="h-[400px]">
        <img src="@/assets/images/image_cibao.jpg" alt="Équipement Agroalimentaire" class="rounded-lg shadow-lg w-full h-full object-cover">
      </div>
      <div>
        <h2 class="text-2xl font-bold text-gray-800 mb-6">Applications spécifiques</h2>
        <p class="text-lg text-gray-600 mb-6">
          Nos équipements sont conçus pour répondre aux besoins spécifiques de chaque secteur de l'industrie agroalimentaire :
        </p>
        <ul class="list-disc list-inside text-lg text-gray-600 space-y-2">
          <li>Industrie laitière</li>
          <li>Transformation des viandes</li>
          <li>Boulangerie industrielle</li>
          <li>Boissons et spiritueux</li>
          <li>Conserverie</li>
        </ul>
      </div>
    </div>

    <div class="bg-blue-50 rounded-lg p-8">
      <h2 class="text-2xl font-bold text-gray-800 mb-6">Services complémentaires</h2>
      <div class="grid md:grid-cols-2 gap-8">
        <div>
          <h3 class="text-xl font-semibold mb-4">Maintenance préventive</h3>
          <p class="text-gray-600 mb-4">
            Programme de maintenance régulière pour garantir la conformité continue de vos installations aux normes sanitaires.
          </p>
          <ul class="list-disc list-inside text-gray-600">
            <li>Inspection régulière</li>
            <li>Nettoyage spécialisé</li>
            <li>Mise à niveau des équipements</li>
          </ul>
        </div>
        <div>
          <h3 class="text-xl font-semibold mb-4">Support technique</h3>
          <p class="text-gray-600 mb-4">
            Une équipe dédiée pour vous accompagner dans l'optimisation de vos processus de production.
          </p>
          <ul class="list-disc list-inside text-gray-600">
            <li>Conseil technique</li>
            <li>Formation du personnel</li>
            <li>Assistance 24/7</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head'

export default {
  name: 'AgroalimentaireView',
  setup() {
    useHead({
      title: 'Agroalimentaire - CIBAO Tuyauterie et Chaudronnerie',
      meta: [
        { name: 'description', content: 'Solutions de chaudronnerie et tuyauterie pour l\'industrie agroalimentaire. Équipements conformes aux normes sanitaires. Expert basé à Saint-Nazaire.' },
        { name: 'keywords', content: 'chaudronnerie agroalimentaire, tuyauterie inox, équipements sanitaires, Saint-Nazaire' },
        { property: 'og:title', content: 'Solutions Agroalimentaires - CIBAO' },
        { property: 'og:description', content: 'Expert en solutions de chaudronnerie pour l\'agroalimentaire' },
        { property: 'og:url', content: 'https://cibao.fr/agroalimentaire' }
      ]
    })
  }
}
</script>
